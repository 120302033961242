import { Component, Vue, VModel, Ref } from "vue-property-decorator";
import { Currency } from "@/types/currency";
import { walletModule } from "@store/namespaces";
import { WalletGetters } from "@store/modules/wallet/types";
import { MultiSelectInstance } from "@/types/components/multiselect";

@Component
export default class CurrencyMultiselect extends Vue {
  @Ref("multiselect") private readonly multiselectRef?: MultiSelectInstance;

  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];

  @VModel({ type: Array })
  private currencies!: Currency[];

  private get items() {
    return this.walletsCurrenciesGetter.map((value) => ({
      text: value,
      value,
    }));
  }

  private selectAll() {
    this.multiselectRef?.selectAll();
  }

  private mounted() {
    this.$watch(
      () => {
        return this.items.length > 0;
      },
      (isReady) => {
        if (!isReady) return;

        this.$emit("ready", this.items);
      },
      { immediate: true }
    );
  }
}
